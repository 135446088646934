<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <el-table ref="_myTb" :data="tableData" style="width: 100%">
      <template v-for="(item, index) in fieldList">
        <el-table-column v-if="typeInference(item)" :key="columnKey(item, index)" v-bind="item">
          <template slot="header" slot-scope="scope">
            <slot :name="slotName(item, 'headerScope')" :scope="scope">
              <template v-if="item.editable">
                {{ scope.column.label }}
                <i class="el-icon-edit-outline"></i>
              </template>
              <template v-else-if="openRtSet && item.operable">
                {{ scope.column.label }}
              </template>
              <template v-else>{{ scope.column.label }}</template>
            </slot>
          </template>
          <template slot-scope="scope">
            <template v-if="item.editable">
              <div v-if="editor(scope.row, scope.column, scope.$index)">
                <slot
                  :name="slotName(item)"
                  :scope="{
                    row: scope.row,
                    column: scope.column,
                    $index: scope.$index
                  }"
                >
                  <el-input v-model.trim="scope.row[item.prop]"></el-input>
                </slot>
              </div>
              <div v-else>
                <i v-if="editorChangeIcon(scope.row, scope.column.property)" class="el-icon-caret-left editorIcon"></i>
                <template v-if="typeof item.formatter === 'function'">
                  <div v-html="item.formatter(scope.row, scope.column, scope.cellValue, scope.index)"></div>
                </template>
                <template v-else>{{ scope.row[item.prop] }}</template>
              </div>
            </template>
            <template v-else-if="item.scope">
              <slot :name="slotName(item, 'scope')" :scope="scope"></slot>
            </template>
            <template v-else-if="item.render">
              <ex-slot :render="item.render" :params="scope" />
            </template>
          </template>
        </el-table-column>
        <el-table-column v-else-if="typeInference(item, 'normal')" :key="index" v-bind="item">
          <template slot="header" slot-scope="scope">
            <slot :name="slotName(item, 'headerScope')" :scope="scope">
              <template v-if="openRtSet && item.operable">
                {{ scope.column.label }}
              </template>
              <template v-else>{{ scope.column.label }}</template>
            </slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
  export default {
    props: {
      tableInfo: {
        type: Object,
        default() {
          return {}
        }
      },
      data: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        openRtSet: false,
        fieldList: [],
        tableData: this.data
      }
    },
    computed: {
      columnKey() {
        return function (item, index) {
          if (item.prop) return item.prop
          return item.type ? item.type : index
        }
      },
      typeInference() {
        return function (item, type) {
          if (item.hidden) return false
          return type ? true : item.editable || item.scope || item.render
        }
      },
      slotName() {
        return function (item, type) {
          // 默认使用 prop 作为 slot name,特殊情况没有 prop 时,使用具体类型的值
          const name = item.prop ? item.prop : item[type]
          return type === 'headerScope' ? `${name}Header` : name
        }
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(val) {
          this.loadData(val)
        }
      }
    },
    created() {
      let tableInfo = this.tableInfo
      // 只显示show为true的列
      const list = [...tableInfo.fieldList]
      this.fieldList = list.filter((item) => {
        // boss要求所有页面操作列宽度默认 200
        if (item.type === 'action') {
          item.width = item.actWidth ? item.actWidth : 200
        }
        if (typeof item.show === 'boolean') return item.show
        return true
      })
    },
    methods: {
      // 获取当前表格实例,用于调用表格提供的方法
      getTbInstance() {
        return this.$refs._myTb
      },
      // 获取当前表格数据
      getCurPageData() {
        return [...this.tableData]
      },
      // 直接绑定表格数据数据方法
      loadData(data) {
        this.tableData = data
      }
    }
  }
</script>
