<template>
  <div>
    <my-table ref="tableInfo" :table-info="tableInfo" :data="tableData" @row-click="handleSelect"></my-table>
    <button @click="getData">aa</button>
  </div>
</template>
<script>
  import MyTable from '@/components/myTable/MyTable'
  import { click } from 'ol/events/condition'
  export default {
    name: 'TableTest',
    components: {
      MyTable
    },
    data() {
      return {
        tableInfo: this.getTableInfo(),
        tableData: [
          {
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          },
          {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          },
          {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          },
          {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄',
            cesi: 'fdksja'
          }
        ]
      }
    },
    mounted() {
      // this.getList()
    },
    methods: {
      // 设置tableInfo
      getTableInfo() {
        return {
          fieldList: [
            { prop: 'date', label: '日期', align: 'center', width: 120 },
            { prop: 'name', label: '姓名', align: 'center', width: 120 },
            { prop: 'address', label: '地址', align: 'center', width: 160 },
            { prop: 'year', label: '年龄', align: 'center', width: 120 },
            { prop: 'cesi', label: '测试', align: 'center', width: 120 },
            {
              label: '操作',
              align: 'center',
              width: 120
            }
          ]
        }
      },
      click() {
        alert(1)
      },
      getList() {
        this.$refs.tableInfo.loadData(this.tableData)
      },
      handleSelect(row) {
        this.$refs.tableInfo.getTbInstance().toggleRowSelection(row)
      },
      getData() {
        let arr = [
          {
            date: '2016-05-02',
            name: '测试',
            address: '上海市普陀区金沙江路 1518 弄',
            cesi: 'fdksja'
          },
          {
            date: '2016-05-04',
            name: '测试',
            address: '上海市普陀区金沙江路 1517 弄',
            cesi: 'fdksja'
          },
          {
            date: '2016-05-01',
            name: '测试',
            address: '上海市普陀区金沙江路 1519 弄',
            cesi: 'fdksja'
          },
          {
            date: '2016-05-03',
            name: '测试',
            address: '上海市普陀区金沙江路 1516 弄',
            year: '2000',
            cesi: 'fdksja'
          }
        ]
        this.tableData = arr
      }
    }
  }
</script>
